<template>
  <b-row class="justify-content-center">
    <b-col md="6" xl="6">
      <b-card title="Detail Profil">
        <b-overlay :show="loading" rounded="sm">
          <b-row>
            <b-col md="6" xl="6">
              <b-form-group label="NIP" label-for="nip">
                <b-form-input id="nip" v-model="form.nip" readonly />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="6">
              <b-form-group label="Email" label-for="email">
                <b-form-input id="email" v-model="form.email" readonly />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" xl="6">
              <b-form-group label="Nama" label-for="name">
                <b-form-input id="name" v-model="form.name" />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="6">
              <b-form-group label="NIK" label-for="nik">
                <b-form-input id="nik" v-model="form.nik" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row> </b-row>
          <b-row>
            <b-col md="6" xl="6">
              <b-form-group label="No Hp" label-for="phone">
                <b-form-input
                  id="phone"
                  maxlength="13"
                  type="number"
                  v-model="form.phone"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Alamat" label-for="address">
                <b-form-textarea id="address" v-model="form.address" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" xl="6">
              <b-form-group label="Upline" label-for="upline">
                <b-form-input id="upline" v-model="form.upline_name" readonly />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="6">
              <b-form-group label="Kode Referral" label-for="referral">
                <b-form-input
                  id="referral"
                  v-model="form.referral_code"
                  readonly
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-button block class="mt-2" variant="success" @click="onUpdateDetail"
            >Update</b-button
          >
        </b-overlay>
      </b-card>
    </b-col>
    <b-col md="6" xl="3">
      <b-card title="Ganti Password">
        <b-form-group label-for="account-new-password" label="New Password">
          <b-input-group class="input-group-merge">
            <b-form-input
              id="account-new-password"
              v-model="newPasswordValue"
              :type="passwordFieldTypeNew"
              name="new-password"
              placeholder="New Password"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="passwordToggleIconNew"
                class="cursor-pointer"
                @click="togglePasswordNew"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group
          label-for="account-retype-new-password"
          label="Retype New Password"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="account-retype-new-password"
              v-model="RetypePassword"
              :type="passwordFieldTypeRetype"
              name="retype-password"
              placeholder="New Password"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="passwordToggleIconRetype"
                class="cursor-pointer"
                @click="togglePasswordRetype"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-button block class="mt-2" variant="success" @click="onChangePassword"
          >Ganti Password</b-button
        >
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BFormFile,
  BOverlay,
  BForm,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import ImageUploader from "vue-image-upload-resize";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BFormFile,
    flatPickr,
    ImageUploader,
    BOverlay,
    BForm,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      loading: false,
      hasImage: false,
      datepicker: {
        dateFormat: "d-m-Y",
      },
      form: {
        id: null,
        nik: null,
        nip: null,
        name: null,
        phone: null,
        email: null,
        address: null,
        status: null,
        image: null,
        upline_id: null,
        upline_name: null,
        created_at: null,
        referral_code: null,
      },
      newPasswordValue: "",
      RetypePassword: "",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",
    };
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  created() {
    this.getData();
  },
  methods: {
    setImage(output) {
      this.hasImage = true;
      this.form.image = output;
    },
    getData() {
      this.$http.get("/user").then((res) => {
        let $userData = res.data.data;
        this.form = $userData;
        localStorage.setItem("userData", JSON.stringify($userData));
      });
    },
    onUpdateDetail() {
      this.$http
        .post("/user/update", this.form)
        .then((res) => {
          this.$bvToast.toast("Update data berhasil", {
            title: "Berhasil",
            variant: "success",
            solid: true,
            autoHideDelay: 1000,
          });
          this.getData();
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast(err.response.data.message, {
            title: "Error",
            variant: "danger",
            solid: true,
            autoHideDelay: 1000,
          });
        });
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },
    onChangePassword() {
      if (this.newPasswordValue !== this.RetypePassword) {
        this.$bvToast.toast("Password tidak sama", {
          title: "Error",
          variant: "danger",
          solid: true,
          autoHideDelay: 1000,
        });
      } else {
        this.$http
          .post("user/password", {
            password: this.newPasswordValue,
            password_confirmation: this.RetypePassword,
          })
          .then((res) => {
            this.$bvToast.toast("Update data berhasil", {
              title: "Berhasil",
              variant: "success",
              solid: true,
              autoHideDelay: 1000,
            });
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
            this.$bvToast.toast(err.response.data.message, {
              title: "Error",
              variant: "danger",
              solid: true,
              autoHideDelay: 1000,
            });
          });
      }
    },
    makeToast(variant = null) {
      this.$bvToast.toast("Toast body content", {
        title: `Variant ${variant || "default"}`,
        variant,
        solid: true,
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
